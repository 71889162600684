import './App.css';
import React, { useState } from 'react';

function App() {
  const [inputValue, setInputValue] = useState('');
  const [phoneNumberDigits, setPhoneNumberDigits] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    setPhoneNumberDigits();
  };

  const handleButtonClick = () => {
    // keep only digits from the input value
    const digits = inputValue.replace(/\D/g, '');
    setPhoneNumberDigits(digits);
    setInputValue('');
    console.log('Get Contacts button clicked');
  };

  const isGetContactsButtonDisabled = inputValue.replace(/\D/g, '').length < 4;

  return (
    <div className="App">
      <header className="App-header">
        <h2>WhatsApp and Telegram Direct Messenger</h2>
        <p>Enter a phone number including the country code.</p>
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter a phone number..."
          style={{ fontSize: '20px', padding: '10px 20px', borderRadius: '10px' }}
        />
        <p>If the number is registered on WhatsApp or Telegram, you will be able to message this number without adding it to your contacts.</p>
        <br />
        <button onClick={handleButtonClick} disabled={isGetContactsButtonDisabled} style={{ fontSize: '20px', padding: '10px 20px', borderRadius: '10px' }}>Start Messaging</button>
        {phoneNumberDigits && (
          <p>
            Phone digits: {phoneNumberDigits}{' '}
            <br />
            <br />
            <a href={`https://wa.me/${phoneNumberDigits}`} target="_blank" rel="noopener noreferrer">
              <button style={{ marginLeft: '10px', backgroundColor: 'green', color: 'white', fontSize: '20px', padding: '10px 20px', borderRadius: '10px' }} disabled={!phoneNumberDigits}>Message on WhatsApp</button>
            </a>
            <br />
            <br />
            <a href={`https://t.me/+${phoneNumberDigits}`} target="_blank" rel="noopener noreferrer">
              <button style={{ marginLeft: '10px', backgroundColor: '#1C93E3', color: 'white', fontSize: '20px', padding: '10px 20px', borderRadius: '10px' }} disabled={!phoneNumberDigits}>Message on Telegram</button>
            </a>
          </p>
        )}
      </header>
    </div>
  );
}

export default App;
